import React from "react";
import { Modal } from "react-bootstrap";

const Popup = ({ onCancel, isModelOpen, handleClick, type, submitting }: any) => {
  return (
    <Modal
      show={isModelOpen}
      backdrop="static"
      keyboard={false}
      animation={false}
      onHide={onCancel}
    >
      <Modal.Header closeButton placeholder=''>
        <Modal.Title placeholder={''}>COI Request Sent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>
          Your request will be visible within Yardi in the next 24 hours. Thank you for
          your patience as we process your request.
        </h3>
        <p>
        Meanwhile, you can follow this record’s status via Jones.
        </p>
        <hr />
        <div className="text-right mb-2">
          <button className="btn btn-primary" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn btn-primary ml-2" onClick={handleClick} disabled={submitting}>
            {submitting ? 'Submitting...' : 'Finish'}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Popup;
