import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { FLUSH, REHYDRATE, PAUSE } from 'redux-persist';
import { PERSIST, PURGE, REGISTER } from 'redux-persist';
import persistConfig from '../config/persist';

import auth from './auth';


const store = configureStore({
  reducer: persistReducer(
    persistConfig,
    combineReducers({ auth: auth.reducer })
  ),
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export type State = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);

export default store;
