import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { State } from '..';


const authAuth = (state: State) => state.auth;

export const tokenSelector = createDraftSafeSelector(
  authAuth,
  (state) => state.token
);

export const clientIdSelector = createDraftSafeSelector(
  authAuth,
  (state) => state.clientId
);

