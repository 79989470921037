import { useCallback, useState, useEffect } from "react";
import API from "../../apis";
import { Project, Vendor, Client, InsuranceRequest } from "../../models";
import * as ObjectTypes from "../../constants/objectTypes";

import { showToastMessage, validateEmail } from "../../utils";

import { toast } from "react-toastify";
import auth from "../../store/auth";
import store from "../../store";

export function useLoad() {
  const [client, setClient] = useState<Client>({
    clientId: 0,
    clientName: "",
    clientCode: "",
    clientDomain: "",
    loggedInUserId: null,
  });
  const [authenticating, setAuthenticating] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [vendor, setVendor] = useState<Vendor>({
    id: 0,
    name: "",
    code: "",
    emailAddress: "",
  });
  const [projects, setProjects] = useState<Project[]>([]);

  const [insurance, setInsurance] = useState<InsuranceRequest>({
    objectId: 0,
    objectType: ObjectTypes.VENDOR,
    projectId: 0,
    email: "",
    requirementId: "",
    clientId: 0,
    vendor: null,
    tenant: null,
    userId: null,
    tabId: "0",
  });

  const [project, setProject] = useState<Project>({
    id: 0,
    name: "",
    code: "",
    requirementList: [],
    requirements: [],
  });

  const [vendorName, setVendorName] = useState<string | null>(null);
  const [emailAddress, setEmailAddress] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onLoad = useCallback(async () => {
    try {
      let clientData: Client;
      let insuranceData: InsuranceRequest = {
        objectId: 0,
        objectType: ObjectTypes.VENDOR,
        projectId: 0,
        email: "",
        requirementId: '',
        clientId: 0,
        vendor: null,
        tenant: null,
        userId: null,
        tabId: "0",
      };

      try {
        setLoading(true);
        store.dispatch(auth.actions.changeClientId(0))
        setAuthenticating(true);
        let parentUrl =  window.parent.document.referrer
        let account = new URLSearchParams(window.location.search).get(
          "account"
        );
        let token = new URLSearchParams(window.location.search).get("token");
        let code = new URLSearchParams(window.location.search).get("code");
        if (!account) {
          showToastMessage("Company not selected!");
          setAuthenticating(false);
          setAuthenticated(false);
          setLoading(false);
          return;
        }
        const {data} = await API.get(
          `Insurance/VerifyClient?account=${account}&domainUrl=${parentUrl}&token=${token}&code=${code}`
        );
    
        if (data) {
          store.dispatch(auth.actions.changeClientId(data.clientId))
          API.defaults.headers["Token"] = token;
          API.defaults.headers["ClientCode"] = data.clientCode;
          API.defaults.headers["Code"] = code;
          clientData = data;
          setClient(data);
          setAuthenticated(true);
          setAuthenticating(false);
        } else {
          setAuthenticated(false);
          setAuthenticating(false);
          setLoading(false);
          return;
        }
      } catch (e) {
        setAuthenticating(false);
        setAuthenticated(false);
        setLoading(false);
        return;
      }
      try {
        let code = new URLSearchParams(window.location.search).get("code");

        let name = new URLSearchParams(window.location.search).get("name");
        if (name) {
          setVendorName(name);
        }
        if (!code) {
          showToastMessage("Vendor Name Or Vendor Code not selected ");
          setLoading(false);
          return;
        }
        let email = new URLSearchParams(window.location.search).get("email");
        setEmailAddress(email);

        const {data} = await API.get(
          `Insurance/GetVendor?code=${code}&clientId=${clientData.clientId}`
        ); 
       
        const vendor = data ? data : {
          id: 0,
          name: name ?? "",
          code: code ?? "",
          emailAddress: email ?? "",
        }

        setVendor(vendor);
        insuranceData = {
          objectId: data?.id,
          objectType: ObjectTypes.VENDOR,
          projectId: 0,
          email: email ?? "",
          requirementId: '',
          userId: clientData.loggedInUserId,
          clientId: clientData.clientId,
          vendor:vendor ,
          tenant: null,
          tabId: "0",
        };
        console.log('insuranceData', insuranceData)
        setInsurance(insuranceData);
      } catch (error : any) {
        showToastMessage(error);
      }

      try {
        const {data} = await API.get(
          `Insurance/GetVendorProjects?vendorId=${
            insuranceData?.objectId ?? 0
          }&clientId=${insuranceData?.clientId ?? 0}`
        );
        if(data){
          setProjects(data);
        }
        
        if (
          data &&
          data.length &&
          data.length === 1
        ) {
          setInsurance({
            ...insuranceData,
            projectId: data[0].id,
          });
          setProject(data[0]);
        }
      } catch (e : any) {
        showToastMessage(e.message);
      }
    } catch (e : any) {
      showToastMessage(e.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    client,
    authenticating,
    authenticated,
    vendor,
    projects,
    insurance,
    setInsurance,
    project,
    setProject,
    vendorName,
    emailAddress,
    loading,
  };
}

export function useStore() {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);

  const onValidate = useCallback(
    async (values: InsuranceRequest, setIsModelOpen:any) => {
      try {
        setValidating(true);
        if (
          values.objectId &&
          values.projectId &&
          values.requirementId !== null &&
          values.email &&
          validateEmail(values.email)
        ) {
          const response = await API.post("Insurance/ValidateInsurance", values);
          setValidating(false);
          if (response.data) {
            showToastMessage(
              "Requirement already generated with selected project and requirement"
            );
          } else {
            toast.dismiss();
            setIsModelOpen(true);
          }
        } else if (!values.objectId && validateEmail(values.email)) {
          toast.dismiss();
          setIsModelOpen(true);
        }
        setValidating(false);
      } catch (e: any) {
        setValidating(false);
        showToastMessage(e?.response?.data);
      }
    },
    [setValidating]
  );

  const onStore = useCallback(
    async (values:any) => {
      try {
        setSubmitting(true);
        const response = await API.post("Insurance/RequestInsurance", values);
        setSubmitting(false);
        if (!response.data) {
          showToastMessage("Submission failed! Please try again.");
        } else {
          window.history.back();
        }
      } catch (e : any) {
        setSubmitting(false);
        showToastMessage(e?.response?.data);
      }
    },
    [setSubmitting]
  );
  return { onStore, submitting, validating, onValidate };
}
