import  axios from 'axios';
import type {  AxiosError } from 'axios';
import { BASE_URL } from './env'
import { clientIdSelector } from './store/auth/selector';
import store from './store';

const appInstance = axios.create({
    baseURL: BASE_URL,
  });

  appInstance.interceptors.request.use(
    (config: any) => {
      const clientId = clientIdSelector(store.getState());
      if(config){
        config.headers['app_clientId'] = `${clientId}`;
      }
    
      return config;
    },
    (error: AxiosError) => error
  );
  

  export default appInstance;