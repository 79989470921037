import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Tenant from './pages/tenant';
import Vendor from './pages/vendor';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store, { persistor } from './store';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
   <Router basename='/'>
     <Routes>
<Route path='/tenant' Component={Tenant}/>
<Route path='/vendor' Component={Vendor}/>
     </Routes>
   </Router>
   </PersistGate>
  </Provider>
  ); 
}

export default App;
