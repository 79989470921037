import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export interface AuthState {
  token?: string;
  clientId?: number;
  clientName?: string;

}

const initialState: AuthState = {
  clientId: 0,

};


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state: AuthState) => (state = initialState),
    changeClientId(state, action: PayloadAction<number>) {
      state.clientId = action.payload == null ? 0 : action.payload;
    },
    
  },
});

export default authSlice;
