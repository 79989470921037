import React, { useCallback, useState } from "react";

import { Card, Form, Col, Row } from "react-bootstrap";
import Select from "react-select";

import { useLoad, useStore } from "./hooks";
import * as TenantModes from "../../constants/tenantModes";
import * as ERROR_MESSAGES from "../../constants/errorMessages";
import Popup from "../../components/Popup";

function Tenant() {
  const {
    insurance,
    setInsurance,
    tenant,
    authenticating,
    authenticated,
    tenantName,
    projectMissing,
    loadingProject,
  } = useLoad();

  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);

  const { submitting, onStore, onValidate, validating } = useStore();

  const handleRequirementChange = useCallback(
    (selectedRequirement: any) => {
      if (selectedRequirement) {
        setInsurance({
          ...insurance,
          requirementId: selectedRequirement.id,
        });
      }
    },
    [insurance, setInsurance]
  );

  const handleNonLeaseRequirementChange = useCallback(
    (selectedRequirement: any) => {
      if (selectedRequirement) {
        console.log(selectedRequirement);
        setInsurance({
          ...insurance,
          requirementId: selectedRequirement.id,
          tabId: selectedRequirement.tabId,
        });
      }
    },
    [insurance, setInsurance]
  );

  const handleEmailChange = useCallback(
    (e: any) => {
      setInsurance({
        ...insurance,
        email: e.target.value,
      });
    },
    [insurance, setInsurance]
  );

  const handleSendClick = useCallback(() => {
    onValidate(insurance, setIsModelOpen, tenant);
  }, [onValidate, setIsModelOpen, insurance, tenant]);

  const handleClick = useCallback(() => {
    onStore(insurance);
  }, [insurance, onStore]);

  const onRequirementFilter = useCallback((option: any, searchText: any) => {
    if (
      option.data.description.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const onCancel = useCallback(async () => {
    setIsModelOpen(false);
  }, [setIsModelOpen]);

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      minHeight: 1,
      paddingTop: 0,
      paddingRight: 2,
      paddingBottom: 1,
      paddingLeft: state.data.label ? 20 : 5,
      backgroundColor: "#b2dbf1",
      color: state.data.requestSent ? "#8f8686" : "#000",
          "&:hover": {
              backgroundColor: "#d8d8d8",
          }
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "#b2dbf1",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "black",
    }),
  };

  if (authenticating || loadingProject) {
    return (
      <div className="p-5">
        <div className="row no-gutters">
          <div className="col-md-12">
            <div className="alert alert-light text-center">
              <p>Authenticating...</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!authenticated) {
    return (
      <div className="p-5">
        <div className="row no-gutters">
          <div className="col-md-12">
            <div className="alert alert-danger text-center">
              <div className="alert alert-danger text-center">
                <h1>{ERROR_MESSAGES.AUTH_FAILED_TITLE}</h1>
                <p>{ERROR_MESSAGES.AUTH_FAILED_MESSAGE}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="row no-gutters">
        <div className="col-sm-12">
          <header className="header py-2">
            <div className="container-fluid">
              <h1 className="m-0">Jones Widget - Request COI</h1>
            </div>
          </header>
          <Card style={{ border: 0 }}>
            <Card.Body className="p-0">
              <div className="container px-4 py-4">
                {projectMissing ? (
                  <div className="row no-gutters">
                    <div className="col-md-12">
                      <div
                        className="card text-center p-2"
                        style={{
                          borderColor: "black",
                          borderRadius: 0,
                          borderWidth: 2,
                        }}
                      >
                        <h1>{projectMissing ? projectMissing.title : null}</h1>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {projectMissing?.message}{" "}
                          <a href={`mailto:` + projectMissing.email}>
                            {projectMissing.email}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <h1>
                      COI Request for{" "}
                      {tenant?.name
                        ? tenant.name
                        : tenant?.code
                        ? tenant.code
                        : tenantName}
                    </h1>
                    <p>
                      Your request will take effect within Yardi in the next 24 hours.{" "}
                    </p>
                    <Form.Group
                      as={Row}
                      controlId="companyName"
                      className="pl-2 pr-2 no-gutters"
                      style={{
                        marginTop: 40,
                      }}
                    >
                      <Form.Label column sm="2">
                        Select Jones Property
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control
                          as="input"
                          value={tenant?.projectName ?? ""}
                          className="form-control readonly-input"
                          disabled={true}
                          custom
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="requirement"
                      className="pl-2 pr-2 no-gutters"
                    >
                      <Form.Label column sm="2">
                        Select Requirements
                      </Form.Label>
                      <Col sm="3">
                        { tenant?.tenantMode === TenantModes.LEASEREQUIREMENT ?
                        <Select
                        key={insurance.requirementId}
                        className="custom-select-input"
                        classNamePrefix="name-select"
                        onChange={handleRequirementChange}
                        getOptionLabel={(option) =>
                          `${option.description} ${
                            option.requestSent ? "(Request Sent)" : ""
                          }`
                        }
                        getOptionValue={(option) => `${option}`}
                        isOptionDisabled={(option) => {
                          return option.requestSent;
                        }}
                        options={tenant?.requirementList}
                        isSearchable={true}
                        filterOption={onRequirementFilter}
                        noOptionsMessage={() => null}
                        placeholder={"Select"}
                        styles={customStyles}
                        value={ tenant.requirementList[0]}
                        isOptionSelected={(option) => {
                          return insurance?.requirementId === option.id
                            ? true
                            : false;
                        }}
                      /> :
                      <Select
                          key={insurance.requirementId}
                          className="custom-select-input"
                          classNamePrefix="name-select"
                          onChange={handleNonLeaseRequirementChange}
                          getOptionLabel={(option) =>
                            `${option.description} ${
                              option.requestSent ? "(Request Sent)" : ""
                            }`
                          }
                          getOptionValue={(option) => `${option}`}
                          isOptionDisabled={(option) => {
                            return option.requestSent;
                          }}
                          options={tenant?.requirements}
                          isSearchable={true}
                          filterOption={onRequirementFilter}
                          noOptionsMessage={() => null}
                          placeholder={"Select"}
                          styles={customStyles}
                          value={
                            tenant?.requirementList && tenant.requirementList.length && insurance?.requirementId !=null && insurance?.requirementId
                              ? tenant?.requirementList.find(
                                  (x) => x.id === insurance?.requirementId && x.tabId === insurance.tabId
                                )
                              : null
                          }
                          isOptionSelected={(option) => {
                            return insurance?.requirementId === option.id && insurance?.tabId === option.tabId
                              ? true
                              : false;
                          }}
                        />}
                        
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="companyEmail"
                      className="pl-2 pr-2 no-gutters"
                    >
                      <Form.Label column sm="2">
                        COI Contact Email
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control
                          as="input"
                          value={insurance?.email}
                          onChange={handleEmailChange}
                          className="form-control custom-input"
                          custom
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  </>
                )}
                <div className="clear button-group no-gutters">
                  <div className="col-sm-6 mt-5">
                    <button
                      disabled={
                          !(insurance.requirementId != null && insurance.requirementId) ||
                        !insurance.email ||
                        submitting ||
                        validating
                      }
                      className="btn btn-primary"
                      onClick={handleSendClick}
                    >
                      {validating ? "Submitting" : "Send Request"}
                    </button>
                    <button
                      className="btn btn-primary ml-2"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      {isModelOpen && (
        <Popup
          isModelOpen={isModelOpen}
          onCancel={onCancel}
          handleClick={handleClick}
          type="tenant"
          submitting={submitting}
        />
      )}
    </>
  );
}

export default Tenant;
